<template>
  <div>
    <header-top-dashboard>
      <v-row class="mt-0">
        <v-col
          cols="12"
          lg="3"
          md="6"
          class="py-0"
          v-for="item in headerCards"
          :key="item.subtitle"
        >
          <v-card class="card-shadow mb-6" :class="item.background">
            <v-card-text class="card-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-white text-uppercase text-h5"
                  >
                    {{ item.subtitle }}
                  </div>
                  <p class="font-weight-600 text-h2 text-white mb-0">
                    {{ item.title }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-btn
                    elevation="0"
                    small
                    max-width="30"
                    min-width="30"
                    height="28"
                    :ripple="false"
                    class="font-weight-600 text-capitalize btn-neutral pa-1 rounded-sm"
                    color="#fff"
                    ><v-icon>fas fa-ellipsis-h</v-icon></v-btn
                  >
                </v-col>
              </v-row>

              <v-progress-linear
                class="progress-shadow my-4"
                :value="item.progressValue"
                rounded
                background-color="#e9ecef"
                color="#2dce89"
              ></v-progress-linear>

              <a
                href="javascript:;"
                class="text-white font-weight-600 text-body-2 ls-0 no-default-hover text-decoration-none"
                >See Details</a
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col lg="4">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Overview
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">Sales Value</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="salesChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Performance
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">Total Orders</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="ordersChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="4">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <v-row>
                <v-col>
                  <div
                    class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
                  >
                    5/23 Projects
                  </div>
                  <p class="font-weight-600 text-typo text-h3 mb-0">
                    Progress Track
                  </p>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                  <v-btn
                    elevation="0"
                    small
                    :ripple="false"
                    class="font-weight-600 text-capitalize btn-neutral py-1 px-2 rounded-sm"
                    color="#fff"
                    >Action</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <v-card-text class="card-padding py-0">
              <v-list class="py-0">
                <v-list-item
                  v-for="(el, index) in progress"
                  :key="el.title"
                  class="px-0 py-3"
                  :class="{ 'border-bottom': index != progress.length - 1 }"
                >
                  <v-list-item-avatar :size="48" class="my-0 me-5">
                    <v-img :alt="`${el.title} avatar`" :src="el.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      v-text="el.title"
                      class="text-typo text-h5 font-weight-600 mb-3"
                    ></v-list-item-title>
                    <v-progress-linear
                      class="progress-shadow"
                      :value="el.progressValue"
                      rounded
                      background-color="#e9ecef"
                      :color="el.color"
                    ></v-progress-linear>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="8">
          <light-table></light-table>
        </v-col>
        <v-col cols="12" lg="4">
          <vector-map-card></vector-map-card>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-6" lg="4">
          <members-card></members-card>
        </v-col>
        <v-col class="pt-6" lg="4">
          <to-do-list></to-do-list>
        </v-col>
        <v-col class="pt-6" lg="4">
          <progress-track></progress-track>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import { salesChart, ordersChart } from "@/components/Charts/Chart";
import VectorMapCard from "./Widgets/VectorMapCard.vue";
import MembersCard from "./Widgets/MembersCard.vue";
import LightTable from "./Widgets/LightTable.vue";
import ToDoList from "./Widgets/ToDoList.vue";
import ProgressTrack from "./Widgets/ProgressTrack.vue";

export default {
  name: "Alternative",
  components: {
    HeaderTopDashboard,
    VectorMapCard,
    MembersCard,
    LightTable,
    ToDoList,
    ProgressTrack,
  },
  data() {
    return {
      salesChartID: "salesChartID",
      ordersChartID: "ordersChartID",
      headerCards: [
        {
          subtitle: "Task Completed",
          title: "8/24",
          progressValue: "30%",
          background: "bg-gradient-primary",
        },
        {
          subtitle: "Contacts",
          title: "123/267",
          progressValue: "50%",
          background: "bg-gradient-info",
        },
        {
          subtitle: "Items Sold",
          title: "200/300",
          progressValue: "80%",
          background: "bg-gradient-danger",
        },
        {
          subtitle: "Notifications",
          title: "50/62",
          progressValue: "90%",
          background: "bg-gradient-default",
        },
      ],
      progress: [
        {
          avatar: require("@/assets/img/bootstrap.jpg"),
          title: "Argon Design System",
          color: "#2dce89",
          progressValue: "60",
        },
        {
          avatar: require("@/assets/img/angular.jpg"),
          title: "Angular Now UI Kit PRO",
          color: "#fb6340",
          progressValue: "100",
        },
        {
          avatar: require("@/assets/img/sketch.jpg"),
          title: "Black Dashboard",
          color: "#f5365c",
          progressValue: "72",
        },
        {
          avatar: require("@/assets/img/react.jpg"),
          title: "React Material Dashboard",
          color: "#11cdef",
          progressValue: "90",
        },
        {
          avatar: require("@/assets/img/vue.jpg"),
          title: "Vue Paper UI Kit PRO",
          color: "#2dce89",
          progressValue: "100",
        },
      ],
    };
  },
  mounted() {
    salesChart.createChart(this.salesChartID);
    ordersChart.createChart(this.ordersChartID);
  },
};
</script>
